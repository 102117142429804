body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 0.3s ease-out;
}

/* ///////////////////////////////////////////////////////////////////////////////// */

.delay-200 {
  animation-delay: 0.2s;
}
.delay-400 {
  animation-delay: 0.4s;
}
.delay-600 {
  animation-delay: 0.6s;
}
.delay-800 {
  animation-delay: 0.8s;
}
.delay-1000 {
  animation-delay: 1s;
}
.delay-1200 {
  animation-delay: 1.2s;
}
.delay-1400 {
  animation-delay: 1.4s;
}
.delay-1600 {
  animation-delay: 1.6s;
}

/* ///////////////////////////////////////////////////////////////////////////////// */

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

.animate-fade-in-scale {
  animation: fadeInScale 0.5s forwards;
}

.animate-fade-out-scale {
  animation: fadeOutScale 0.5s forwards;
}

@keyframes moveArrow {
  0% {
    left: 0;
  }
  50% {
    left: 2px;
  }
  100% {
    left: 0;
  }
}

.animate-arrow {
  position: relative;
  animation: moveArrow 1s ease-in-out infinite;
}

/* Example for a specific div with the class .hidden-scroll */
.hidden-scroll {
  overflow: hidden; /* Hide scrollbars */
}

/* Optional: If you want to allow scrolling without visible scrollbars */
.hidden-scroll {
  overflow: auto; /* or overflow-y: auto; */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.hidden-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hidden-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Custom styles for the chat page */
.message {
  max-width: 100%;
}

.message-content {
  word-break: break-word;
}

.received .message-content {
  background-color: #e5e5ea;
}

.message-input input {
  border-radius: 20px;
  background-color: #ffffff;
  padding: 10px;
}

.message-input button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: auto; /* Ensures scrolling works */
}
